//? Light
@font-face {
    font-family: 'SF PRO';
    font-weight: 300;
    src: url('../fonts/sf-pro-text-light.eot');
    src: url('../fonts/sf-pro-text-light.eot?#iefix') format('embedded-opentype'), url('../fonts/sf-pro-text-light.woff') format('woff'), url('../fonts/sf-pro-text-light.ttf') format('truetype'), url('../fonts/sf-pro-text-light.svg') format('svg');
}

//? Regular
@font-face {
    font-family: 'SF PRO';
    font-weight: 400;
    src: url('../fonts/sf-pro-text-regular.eot');
    src: url('../fonts/sf-pro-text-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/sf-pro-text-regular.woff') format('woff'), url('../fonts/sf-pro-text-regular.ttf') format('truetype'), url('../fonts/sf-pro-text-regular.svg') format('svg');
}

//? Medium
@font-face {
    font-family: 'SF PRO';
    font-weight: 500;
    src: url('../fonts/sf-pro-text-medium.eot');
    src: url('../fonts/sf-pro-text-medium.eot?#iefix') format('embedded-opentype'), url('../fonts/sf-pro-text-medium.woff') format('woff'), url('../fonts/sf-pro-text-medium.ttf') format('truetype'), url('../fonts/sf-pro-text-medium.svg') format('svg');
}

//? Semibold
@font-face {
    font-family: 'SF PRO';
    font-weight: 600;
    src: url('../fonts/sf-pro-text-semibold.eot');
    src: url('../fonts/sf-pro-text-semibold.eot?#iefix') format('embedded-opentype'), url('../fonts/sf-pro-text-semibold.woff') format('woff'), url('../fonts/sf-pro-text-semibold.ttf') format('truetype'), url('../fonts/sf-pro-text-semibold.svg') format('svg');
}

//? Bold
@font-face {
    font-family: 'SF PRO';
    font-weight: 700;
    src: url('../fonts/sf-pro-text-bold.eot');
    src: url('../fonts/sf-pro-text-bold.eot?#iefix') format('embedded-opentype'), url('../fonts/sf-pro-text-bold.woff') format('woff'), url('../fonts/sf-pro-text-bold.ttf') format('truetype'), url('../fonts/sf-pro-text-bold.svg') format('svg');
}
