.approvalPage {
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

    &__container {
        max-width: 1360px;
        width: 100%;
        margin: 0 auto;
        padding: 0 16px;

        @media all and (max-width: 768px) {
            padding: 0;
        }

        &__headerWrapper {
            display: flex;
            flex-direction: column;
            gap: 12px;
            font-family: 'Open Sans', sans-serif;

            &__approvalName {
                padding: 16px 0 0;
                font-size: 18px;
                font-weight: 600;

                @media all and (max-width: 768px) {
                    padding: 16px 16px 0;
                }
            }

            &__approvalInfo {
                display: flex;
                justify-content: space-between;
                margin-bottom: 32px;

                @media all and (max-width: 768px) {
                    padding: 0 16px;
                }

                &__dateCreated {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                }

                &__dateExpire {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    text-align: right;
                }
            }

            &__approvalStatus {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 82px;
                padding: 0 16px;
                font-size: 16px;
                font-family: 'Open Sans', sans-serif;
                text-align: center;
                box-sizing: border-box;
            }
        }

        &__documentPDFWrapper {
            @media all and (max-width: 768px) {
                padding: 0 16px;
            }

            &__documentPDF {
                width: 100%;
                height: 55vh;
                max-height: 750px;
            }
        }

        &__footerWrapper {
            display: flex;
            justify-content: space-between;
            gap: 24px;
            margin-top: 32px;
            font-family: 'Open Sans', sans-serif;

            @media all and (max-width: 768px) {
                padding: 0 16px;
            }

            &.changes {
                justify-content: flex-end;
            }

            &__buttonApprove {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 180px;
                height: 40px;
                color: #fff;
                font-size: 16px;
                font-weight: 500;
                background: #005fd0;
                border: 1px solid #005fd0;
                border-radius: 4px;
                cursor: pointer;
            }

            &__buttonChanges {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 180px;
                height: 40px;
                color: #005fd0;
                font-size: 16px;
                font-weight: 500;
                background: #fff;
                border: 2px solid #005fd0;
                border-radius: 4px;
                cursor: pointer;
            }
        }
    }
}
