.csatv2_success,
.csatv2_loading {
    display: block;
    max-width: 816px;
    margin: 40px auto;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: 0.1s opacity;
    height: 0px;

    &.active {
        opacity: 1;
        visibility: visible;
        height: max-content;
        padding: 30px;
    }

    .loading {
        display: block;
        margin-top: 17px;

        img {
            width: 250px;
            height: auto;
        }
    }

    .border {
        display: block;
        background: url('../../assets/images/logo_border.svg') center no-repeat;
        background-size: 100%;
        width: 128px;
        height: 1px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 6px;
    }
    h2 {
        margin-top: 17px;
        font-size: 38px;
        line-height: 46px;
        font-weight: 400;
    }
    p {
        margin-top: 8px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
    }
}

.csatv2_form {
    display: block;
    max-width: 816px;
    margin: 40px auto;
    visibility: hidden;
    opacity: 0;
    transition: 0.1s opacity;
    height: 0px;

    &.active {
        opacity: 1;
        visibility: visible;
        height: max-content;
        padding: 30px;
    }

    .logo {
        display: block;
        margin-bottom: 24px;
        margin-left: auto;
        margin-right: auto;
    }
    .question {
        display: block;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 24px;
        text-align: center;

        strong {
            font-weight: 600;
        }
        span {
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.25);
            margin-left: 8px;
        }
    }

    .csatv2 {
        display: block;
        text-align: center;

        &_item {
            display: inline-block;
            vertical-align: middle;
            margin: 0 10px;
            cursor: pointer;

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }

            img {
                display: block;
                width: auto;
                height: 50px;
                margin-left: auto;
                margin-right: auto;
            }

            .title {
                display: block;
                font-size: 10px;
                line-height: 20px;
                color: #00000073;
                margin-bottom: 8px;
                text-align: center;
            }
        }
    }
}
