.nps_form {
    display: block;
    max-width: 816px;
    margin: 40px auto;
    visibility: hidden;
    opacity: 0;
    transition: 0.1s opacity;
    height: 0px;

    &.active {
        opacity: 1;
        visibility: visible;
        height: max-content;
        padding: 30px;
    }

    .logo {
        margin-bottom: 24px;
    }
    .message {
        display: block;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 32px;
    }
    .question {
        display: block;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 24px;

        strong {
            font-weight: 600;
        }
        span {
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.25);
            margin-left: 8px;
        }
    }

    textarea {
        display: block;
        padding: 7.5px 10px;
        width: calc(100% - 20px);
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 28px;
        border: 1px dashed rgba(0, 0, 0, 0.45);
        border-radius: 10px;
        box-shadow: none;
        resize: none;
        text-decoration: none;
        outline: 0;
    }

    button {
        position: relative;
        display: block;
        width: 100%;
        padding: 9px 0;
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        color: #ffffff;
        background: #1677ff;
        border: none;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.02);
        border-radius: 6px;
        text-decoration: none;
        outline: 0;
        cursor: pointer;
        transition: 0.2s ease all;

        &:hover {
            background: #000;
        }
    }

    .nps {
        display: block;

        &_item {
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px;
            cursor: pointer;

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }

            img {
                display: block;
                width: auto;
                height: 48px;
            }
        }
    }
}

.nps_success,
.nps_loading {
    display: block;
    max-width: 816px;
    margin: 40px auto;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: 0.1s opacity;
    height: 0px;

    &.active {
        opacity: 1;
        visibility: visible;
        height: max-content;
        padding: 30px;
    }

    .loading {
        display: block;
        margin-top: 17px;

        img {
            width: 250px;
            height: auto;
        }
    }

    .border {
        display: block;
        background: url('../../assets/images/logo_border.svg') center no-repeat;
        background-size: 100%;
        width: 128px;
        height: 1px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 6px;
    }
    h2 {
        margin-top: 17px;
        font-size: 38px;
        line-height: 46px;
        font-weight: 400;
    }
    p {
        margin-top: 8px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
    }
}
